import revive_payload_client_H3VZn3fbyK from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._7ljbkqxnobjezucyq7h6tjm5la/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_u27vVxdPIz from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._7ljbkqxnobjezucyq7h6tjm5la/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_tAcw5UxLMc from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._7ljbkqxnobjezucyq7h6tjm5la/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_xNfML76p7o from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.25.0_vite@5.4.10_@types+node@22.9.0_sass@1.80_2hrjwkvbt4fgl3f4u2dad22byu/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_Kf8AdZZDoH from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._7ljbkqxnobjezucyq7h6tjm5la/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Mu9XQANaCq from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._7ljbkqxnobjezucyq7h6tjm5la/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_mEVsgjYEqw from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._7ljbkqxnobjezucyq7h6tjm5la/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_zbacBAXhj0 from "/opt/buildhome/repo/web-locals/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/web-locals/.nuxt/components.plugin.mjs";
import prefetch_client_CJayrB9WyQ from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._7ljbkqxnobjezucyq7h6tjm5la/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_MUCcYA5CHa from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+icon@1.7.2_magicast@0.3.5_rollup@4.25.0_vite@5.4.10_@types+node@22.9.0_sass@1.80.6_ters_heflqjldenqmwtsdcid7oyxt5i/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import imgwin_client_SiHf1aOUrN from "/opt/buildhome/repo/web/plugins/imgwin.client.ts";
import sentry_client_A9HLV6Ua2r from "/opt/buildhome/repo/web/plugins/sentry.client.ts";
import vue_final_modal_qbCQSD8hof from "/opt/buildhome/repo/web/plugins/vue-final-modal.ts";
import vue_gtm_client_stBjLl0OeM from "/opt/buildhome/repo/web-locals/plugins/vue-gtm.client.ts";
export default [
  revive_payload_client_H3VZn3fbyK,
  unhead_u27vVxdPIz,
  router_tAcw5UxLMc,
  _0_siteConfig_xNfML76p7o,
  payload_client_Kf8AdZZDoH,
  navigation_repaint_client_Mu9XQANaCq,
  chunk_reload_client_mEVsgjYEqw,
  plugin_zbacBAXhj0,
  components_plugin_KR1HBZs4kY,
  prefetch_client_CJayrB9WyQ,
  plugin_MUCcYA5CHa,
  imgwin_client_SiHf1aOUrN,
  sentry_client_A9HLV6Ua2r,
  vue_final_modal_qbCQSD8hof,
  vue_gtm_client_stBjLl0OeM
]