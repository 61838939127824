<script setup lang="ts">
const toTop = () => clearError({ redirect: "/" });
</script>

<template>
  <div class="flex flex-col items-center justify-center py-96">
    <p class="text-xl">ページが見つかりませんでした</p>
    <a
      class="inline-flex text-white bg-famitei-green hover:bg-green-800 font-medium rounded-lg text-sm px-5 py-3 text-center my-4 cursor-pointer"
      @click="toTop"
      >ファミリー庭園トップページ</a
    >
  </div>
</template>
