import validate from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._7ljbkqxnobjezucyq7h6tjm5la/node_modules/nuxt/dist/pages/runtime/validate.js";
import history_45global from "/opt/buildhome/repo/web/middleware/history.global.ts";
import pageview_45global from "/opt/buildhome/repo/web/middleware/pageview.global.ts";
import redirect_45global from "/opt/buildhome/repo/web/middleware/redirect.global.ts";
export const globalMiddleware = [
  validate,
  history_45global,
  pageview_45global,
  redirect_45global
]
export const namedMiddleware = {}